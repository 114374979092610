import {Controller} from 'stimulus'

export default class extends Controller {
  connect() {
    if (this.data.get('loaded') != 'true') {
      this.load()
    }
  }

  load() {
    const url = this.data.get("url")
    if (url == null) throw new Error('url not found')
    fetch(url, { credentials: 'same-origin' })
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html
        this.enableAutofocus()
        this.data.set('loaded', 'true')
      })
      .catch(() => {
        // console.error('Kunde inte ladda modaler. Var god ladda om sidan.')
      })
  }

  enableAutofocus() {
    window.$('.modal').on('show.bs.modal', function () {
      const modal = window.$(this)
      const autofocus = modal.find('input[data-modal-autofocus]')[0]
      if (autofocus && autofocus instanceof HTMLInputElement) {
        requestAnimationFrame(() => {
          autofocus.focus()
        })
      }
    })
  }
}
