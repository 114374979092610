import {Controller} from 'stimulus';
import Sounds from '../sounds';

export default class extends Controller {
  connect () {
    this.toggle = this.toggle.bind(this);
    this.element.addEventListener('click', this.toggle);
    this.render();
  }

  toggle () {
    if (Sounds.enabled) {
      Sounds.disable();
      this.render();
    } else {
      Sounds.enable();
      Sounds.okMessage('Ok');
      this.render();
    }
  }

  disconnect () {
    this.element.removeEventListener('click', this.toggle);
  }

  render () {
    if (Sounds.enabled) {
      this.element.classList.remove("fa-volume-off");
      this.element.classList.add("fa-volume-up");
    } else {
      this.element.classList.add("fa-volume-off");
      this.element.classList.remove("fa-volume-up");
    }
  }
}
