// import 'phoenix_html';
import jQuery from 'jquery';
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import {Application} from 'stimulus';
import LiveSocket from "phoenix_live_view";
import {definitionsFromContext} from 'stimulus/webpack-helpers';
import * as Dropzone from 'dropzone';
import 'select2';
import "./plugins/bootstrap";
import socket from './socket';

Rails.start();
Turbolinks.start();

const liveSocket = new LiveSocket("/live");

window.$ = jQuery;

window.showUjsModal = function (title, content) {
  window.$('#ujsModalContent').html(`
    <div class="modal-header">
      <h5 class="modal-title" id="ujsModalLabel">${title}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">${content}</div>
  `);
  window.$('#ujsModal').modal({});

  setTimeout(() => {
    window.$('#ujsModalContent select').not('.disable-select2').select2(SELECT2_OPTIONS);
  }, 100);
};

window.hideUjsModal = function () {
  window.$('#ujsModal').modal('hide');
};

const SELECT2_OPTIONS = {width: '100%'};

Dropzone.options.createAccountsPayableInvoiceDropzone = {
  paramName: 'file',
  resizeWidth: 1600
}

// https://github.com/stimulusjs/stimulus/blob/master/INSTALLING.md
const application = Application.start()
const context = require.context('./controllers', true, /\.jsx?$/)
application.load(definitionsFromContext(context))

document.addEventListener('click', (event) => {
  const target = event.target
  if (!(target instanceof HTMLElement)) return

  // catching remove-parent-tr is on click since the remove links are often generated generated after
  // turbolinks:load event
  if (target.getAttribute('data-removeaction') === 'remove-parent-tr') {
    event.preventDefault()
    for (let parent = target.parentElement; parent != null; parent = parent.parentElement) {
      if (parent.tagName === 'TR') {
        const tbody = parent.parentElement
        if (!(tbody instanceof HTMLElement)) break
        tbody.removeChild(parent)
        break
      }
    }
    return
  }
});

document.addEventListener('liveSocket:reconnect', () => {
  liveSocket.connect();
});

document.addEventListener('turbolinks:load', () => {
  liveSocket.connect();

  // enable select2
  window.$('select').not('.disable-select2').select2(SELECT2_OPTIONS);
  window.$('[data-toggle="tooltip"]').tooltip()

  const globalAutoselect = document.querySelectorAll('[data-autoselect="true"]')[0]
  if (globalAutoselect instanceof HTMLInputElement) globalAutoselect.select()

  document.querySelectorAll('[data-appendaction="append-tr-template"]')
    .forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault()
        if (!(element instanceof HTMLElement)) return

        const timestamp = new Date().getTime()

        const template = (element.getAttribute('data-template') || 'template-missing')
          .replace(/\[0\]/g, '[' + timestamp + ']')
          .replace(/_0_/g, '_' + timestamp + '_')
        const targetId = element.getAttribute('data-appendtarget')
        const target = document.getElementById(String(targetId))
        if (!(target instanceof HTMLElement)) throw new Error()

        const tbody = document.createElement('tbody')
        tbody.innerHTML = template

        const tr = tbody.children[0]
        target.appendChild(tr)

        const autoselect = tr.querySelectorAll('[data-autoselect="true"]')[0]
        if ((autoselect instanceof HTMLInputElement) && autoselect != null) {
          autoselect.select()
        }
      })
    })

  const loader = document.getElementById('loader');
  if (loader != null) loader.classList.add('fadeOut');
})

let elementsWithPersistentScrolls = ['.turbolinks-disable-scroll'];

let persistentScrollsPositions = {};

window.$(document).on('turbolinks:before-visit', function () {
  var i, len, results, selector;
  persistentScrollsPositions = {};
  results = [];
  for (i = 0, len = elementsWithPersistentScrolls.length; i < len; i++) {
    selector = elementsWithPersistentScrolls[i];
    results.push(persistentScrollsPositions[selector] = window.$(selector).scrollTop());
  }
  return results;
});

window.$(document).on('turbolinks:load', function () {
  var results, scrollTop, selector;
  results = [];
  for (selector in persistentScrollsPositions) {
    scrollTop = persistentScrollsPositions[selector];
    results.push(window.$(selector).scrollTop(scrollTop));
  }
  return results;
});
