import {Controller} from 'stimulus'
import {copyTextToClipboard} from '../utils'

export default class extends Controller {
  static targets = ['source']

  get sourceInputElement () {
    const element = this.targets.find('source')
    if (element instanceof HTMLInputElement) return element
    return null
  }

  get sourceTextAreaElement () {
    const element = this.targets.find('source')
    if (element instanceof HTMLTextAreaElement) return element
    return null
  }

  get sourceInnerHTML () {
    const element = this.targets.find('source')
    if (element instanceof HTMLElement) return element.innerHTML
    return null
  }

  copy (event) {
    event.preventDefault()

    const inputElement = this.sourceInputElement
    if (inputElement) {
      inputElement.select()
      document.execCommand('copy')
      return
    }

    const textAreaElement = this.sourceTextAreaElement
    if (textAreaElement) {
      textAreaElement.select()
      document.execCommand('copy')
      return
    }

    const innerHTML = this.sourceInnerHTML
    if (innerHTML != null) {
      copyTextToClipboard(innerHTML)
      return
    }

    throw new Error('nothing to copy')
  }

  connect () {
    if (document.queryCommandSupported('copy')) {
      this.element.classList.add('clipboard--supported')
    }
  }
}
