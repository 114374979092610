import {Controller} from 'stimulus'

export default class extends Controller {
  connect() {
    if (this.data.get('loaded') != 'true') {
      this.load()
    }
  }

  load() {
    const url = this.data.get("url")
    if (url == null) throw new Error('url not found')
    fetch(url, { credentials: 'same-origin' })
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html
        this.enableSidebarDropdowns()
        this.data.set('loaded', 'true')
      })
      .catch(() => {
        this.element.innerHTML = 'Kunde inte ladda meny. Ett okänt fel har uppstått.'
      })
  }

  enableSidebarDropdowns() {
    window.$('.sidebar .sidebar-menu li a').on('click', function () {
      const $this = window.$(this)

      if ($this.parent().hasClass('open')) {
        $this
          .parent()
          .children('.dropdown-menu')
          .slideUp(200, () => {
            $this.parent().removeClass('open')
          })
      } else {
        $this
          .parent()
          .parent()
          .children('li.open')
          .children('.dropdown-menu')
          .slideUp(200)

        $this
          .parent()
          .parent()
          .children('li.open')
          .children('a')
          .removeClass('open')

        $this
          .parent()
          .parent()
          .children('li.open')
          .removeClass('open')

        $this
          .parent()
          .children('.dropdown-menu')
          .slideDown(200, () => {
            $this.parent().addClass('open')
          })
      }
    })
  }
}
