import {Controller} from 'stimulus';
import * as sv from 'date-fns/locale/sv';
import * as DateFns from 'date-fns';

export default class extends Controller {
  connect() {
    this.renderInnerHTML = this.renderInnerHTML.bind(this);
    this.renderInnerHTML();
    this.interval = setInterval(this.renderInnerHTML, 10000);
    document.addEventListener('turbolinks:load', this.renderInnerHTML);
  }

  disconnect() {
    clearInterval(this.interval);
    document.removeEventListener('turbolinks:load', this.renderInnerHTML);
  }

  renderInnerHTML() {
    const value = DateFns.formatDistance(this.date, new Date(), {locale: sv});
    this.element.innerHTML = value;
  }

  get date() {
    const date = new Date(this.data.get('timestamp'));
    return date;
  }
}
