import {Controller} from 'stimulus'

export default class extends Controller {
  connect() {
    this.load()
  }

  load() {
    const url = this.data.get("url")
    if (url == null) throw new Error('url not found')
    fetch(url, { credentials: 'same-origin' })
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html
      })
      .catch(() => {
        this.element.innerHTML = 'Kunde inte ladda innehåll. Ett okänt fel har uppstått.'
      })
  }
}
