export function isHTMLElement (element) {
  return (element instanceof HTMLElement)
}

export function forceHTMLElement (element) {
  if (isHTMLElement(element)) return
  throw new Error('element was not an HTMLElement')
}

// https://stackoverflow.com/a/30810322
export function copyTextToClipboard (text) {
  const textArea = document.createElement('textarea')

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = 'fixed'
  textArea.style.top = '0'
  textArea.style.left = '0'

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em'
  textArea.style.height = '2em'

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = '0'

  // Clean up any borders.
  textArea.style.border = 'none'
  textArea.style.outline = 'none'
  textArea.style.boxShadow = 'none'

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = 'transparent'

  textArea.value = text

  document.body.appendChild(textArea)

  textArea.select()

  try {
    const successful = document.execCommand('copy')
    document.body.removeChild(textArea)
    return !!successful
  } catch (err) {
    document.body.removeChild(textArea)
    return false
  }
}

export function findParentElement (element, tagName) {
  for (let parent = element.parentElement; parent != null; parent = parent.parentElement) {
    if (parent.tagName === tagName) return parent
  }
  return null
}
