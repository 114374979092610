const supported = typeof SpeechSynthesisUtterance !== 'undefined' && typeof window.speechSynthesis !== 'undefined';

class Speech {
  say (message) {
    if (!supported) return;
    const speechMessage = new SpeechSynthesisUtterance(message);
    window.speechSynthesis.speak(speechMessage);
  }
}

const singleton = new Speech();

export default singleton;
