import {Controller} from 'stimulus'

const CHECKBOX_ROW_SELECTED_CLASS = 'checkbox-table--checked-tr'

export default class extends Controller {
  static targets = ['rowTr', 'checkedValues', 'allCheckbox', 'rowCheckbox']

  connect () {
    this.element.classList.add('checkbox-table--supported')
    this.setCheckedValuesAndToggleClasses()
  }

  checkAll (event) {
    const target = event.target
    if (!(target instanceof HTMLInputElement)) throw new Error('invalid target')
    const checked = target.checked
    this.allCheckboxInputs.forEach((checkbox) => checkbox.checked = checked)
    this.rowCheckboxInputs.forEach((checkbox) => checkbox.checked = checked)
    this.setCheckedValuesAndToggleClasses()
  }

  checkRow () {
    this.setCheckedValuesAndToggleClasses()
  }

  get checkedValuesInputs () {
    const inputs = []
    this.targets.findAll('checkedValues').forEach((element) => {
      if (element instanceof HTMLInputElement) inputs.push(element)
    })
    return inputs
  }

  get allCheckboxInputs () {
    const inputs = []
    this.targets.findAll('allCheckbox').forEach((element) => {
      if (element instanceof HTMLInputElement) inputs.push(element)
    })
    return inputs
  }

  get rowCheckboxInputs () {
    const inputs = []
    this.targets.findAll('rowCheckbox').forEach((element) => {
      if (element instanceof HTMLInputElement) inputs.push(element)
    })
    return inputs
  }

  get rowTrs () {
    const elements = []
    this.targets.findAll('rowTr').forEach((element) => {
      if (element instanceof HTMLElement) elements.push(element)
    })
    return elements
  }

  get toggleables () {
    const elements = []
    this.targets.findAll('toggleable').forEach((element) => {
      if (element instanceof HTMLElement) elements.push(element)
    })
    return elements
  }

  get disableables () {
    const elements = []
    this.targets.findAll('disableable').forEach((element) => {
      if (element instanceof HTMLFieldSetElement) elements.push(element)
    })
    return elements
  }

  get counts () {
    const elements = []
    this.targets.findAll('count').forEach((element) => {
      if (element instanceof HTMLElement) elements.push(element)
    })
    return elements
  }

  get values () {
    const values = []
    this.rowCheckboxInputs.forEach((checkbox) => {
      // TODO: use data-checkbox-table-value instead of data-value
      const value = checkbox.dataset['value']
      if (value == null || value === '') throw new Error('missing value in checkbox')
      if (checkbox.checked) values.push(value)
    })
    return values
  }

  setCheckedValuesAndToggleClasses () {
    const values = this.values

    // set all input fields with the joined values
    const joinedValues = values.join(',')
    this.checkedValuesInputs.forEach((input) => {
      input.value = joinedValues
    })

    // add and remove classes
    this.rowTrs.forEach((trElement) => {
      const value = trElement.dataset['value']
      if (value != null && values.includes(value)) {
        trElement.classList.add(CHECKBOX_ROW_SELECTED_CLASS)
      } else {
        trElement.classList.remove(CHECKBOX_ROW_SELECTED_CLASS)
      }
    })

    // show and hide toggle elements
    this.toggleables.forEach((toggleableElement) => {
      if (values.length > 0) {
        toggleableElement.classList.remove('d-none')
      } else {
        toggleableElement.classList.add('d-none')
      }
    })

    // disable elements
    this.disableables.forEach((disableableElement) => {
      if (values.length > 0) {
        disableableElement.disabled = false
      } else {
        disableableElement.disabled = true
      }
    })

    // show and hide toggle elements
    this.counts.forEach((countElement) => {
      countElement.innerHTML = `${values.length}`
    })
  }
}
