import {Controller} from 'stimulus'
// import HI from '../humaninput'

export default class extends Controller {
  static targets = ['target']

  get targetElement () {
    const elements = []
    this.targets.findAll('target').forEach((element) => {
      if (element instanceof HTMLInputElement) elements.push(element)
    })
    return elements[0]
  }

  get targetKey() {
    const targetElement = this.targetElement
    if (targetElement == null) {
      // console.error('no target element')
      return 'invalid-key'
    }

    const key = targetElement.dataset['key']
    if (key == null) {
      // console.error('no target key')
      return 'invalid-key'
    }

    return key
  }

  focusTargetElement = (event) => {
    event.preventDefault()
    event.stopPropagation();
    const targetElement = this.targetElement
    if (targetElement != null) {
      targetElement.focus()
      targetElement.select()
    }
  }

  connect () {
    // HI.on(this.targetKey, this.focusTargetElement)
  }

  disconnect () {
    // HI.off(this.targetKey, this.focusTargetElement)
  }
}
