import Speech from './speech';

const supportsLocalStorage = typeof localStorage !== 'undefined';

class Sounds {
  enable () {
    this.enabled = true;

    this.okSound = new Audio("/sounds/ok.wav");
    this.honkSound = new Audio("/sounds/honk.wav");
    this.hornSound = new Audio("/sounds/horn.wav");

    if (supportsLocalStorage && localStorage.getItem('sound-enabled') !== 'true') {
      localStorage.setItem('sound-enabled', 'true');
    }
  }

  disable () {
    this.enabled = false;

    if (supportsLocalStorage) {
      localStorage.removeItem('sound-enabled');
    }
  }

  ok () {
    return new Promise((resolve) => {
      if (this.enabled && this.okSound) {
        this.okSound.play()
          .then(() => {
            console.log('played sound: ok');
            resolve();
          })
          .catch(() => {
            console.log('failed to play sound: ok');
            resolve();
          });
      } else {
        resolve();
      }
    });
  }

  honk () {
    return new Promise((resolve) => {
      if (this.enabled && this.honkSound) {
        this.honkSound.play()
          .then(() => {
            console.log('played sound: honk');
            resolve();
          })
          .catch(() => {
            console.log('failed to play sound: honk');
            resolve();
          });
      } else {
        resolve();
      }
    });
  }

  horn () {
    return new Promise((resolve) => {
      if (this.enabled && this.hornSound) {
        this.hornSound.play()
          .then(() => {
            console.log('played sound: horn');
            resolve();
          })
          .catch(() => {
            console.log('failed to play sound: horn');
            resolve();
          });
      } else {
        resolve();
      }
    });
  }

  okMessage (message) {
    this.ok()
      .then(() => {
        if (this.enabled) {
          Speech.say(message);
        }
      });
  }
}

const singleton = new Sounds();

if (supportsLocalStorage && localStorage.getItem('sound-enabled') === 'true') {
  singleton.enable();
}

export default singleton;
