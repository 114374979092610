import {Controller} from 'stimulus'

// The decision timeout is the time in milliseconds that makes sure that the barcode scanner doesn't
// select the first hit when multiple are available
const DECISION_TIMEOUT = 2000

export default class extends Controller {
  static targets = ['itemFilterList', 'itemFilterItem', 'itemFilterForm', 'itemFilterInput']

  hitIndex
  lastInput

  connect () {
    this.hitIndex = 0
    this.lastInput = new Date().getTime()

    this.itemFilterInput.addEventListener('keydown', (event) => {
      if (event.key == 'ArrowDown') {
        event.preventDefault()
        this.hitIndex++
        this.moveActive()
      }
      if (event.key == 'ArrowUp') {
        event.preventDefault()
        this.hitIndex--
        if (this.hitIndex < 0) this.hitIndex = 0
        this.moveActive()
      }
    })
    this.itemFilterInput.addEventListener('input', () => {
      this.hitIndex = 0
      this.filterItems()
      this.lastInput = new Date().getTime()
    })
    this.filterItems()
  }

  get itemFilterList () {
    const lists = []
    this.targets.findAll('itemFilterList').forEach((element) => {
      if (element instanceof HTMLDivElement) lists.push(element)
    })
    return lists[0]
  }

  get itemFilterInput () {
    const inputs = []
    this.targets.findAll('itemFilterInput').forEach((element) => {
      if (element instanceof HTMLInputElement) inputs.push(element)
    })
    return inputs[0]
  }

  get itemFilterItems () {
    const items = []
    this.targets.findAll('itemFilterItem').forEach((element) => {
      if (element instanceof HTMLAnchorElement) items.push(element)
    })
    return items
  }

  moveActive () {
    let index = 0
    this.itemFilterItems.forEach((item) => {
      if (index == this.hitIndex) {
        item.classList.add('active')
        index++
      } else {
        item.classList.remove('active')
        index++
      }
    })
  }

  filterItems () {
    const queries = this.itemFilterInput.value
        .trim()
        .toLowerCase()
        .split(' ')
        .filter((val) => val !== '')
    let results = 0

    const scoreItems = this.itemFilterItems
        .map((item) => {
          this.itemFilterList.removeChild(item)

          if (queries.length === 0) {
            return {score: 0, item}
          }

          const value = ('' + item.dataset['itemName'] + item.dataset['itemNumber'] + item.dataset['itemPrice'] + item.dataset['itemBarcodes']).toLowerCase()
          const score = queries.filter((query) => value.includes(query)).length
          if (score !== queries.length) return {score: 0, item}
          return {score, item}
        })

    scoreItems.sort((a, b) => {
      return b.score - a.score
    })

    scoreItems.forEach(({score, item}) => {
      if (score > 0 && results < 8) {
        results++
        item.classList.remove('d-none')
      } else {
        item.classList.add('d-none')
      }

      this.itemFilterList.appendChild(item)
    })

    this.moveActive()
  }

  submitSearch (event) {
    event.preventDefault()

    const items = this.itemFilterItems.filter((item) => {
      return item.classList.contains('active')
    })
    const item = items[0]

    const decisionTime = new Date().getTime() - this.lastInput
    const singleHitBarcodeBlock = items.length > 1 && decisionTime < DECISION_TIMEOUT

    if (item && !singleHitBarcodeBlock) {
      const evt = document.createEvent('MouseEvents')
      evt.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null)
      item.dispatchEvent(evt)

      this.itemFilterInput.focus()
      this.itemFilterInput.value = ''
      this.hitIndex = 0

      this.filterItems()
    }
  }
}
