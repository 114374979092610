import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    if (this.data.get('loaded') != 'true') {
      this.load();
    }
  }

  load() {
    const url = this.data.get("url");
    if (url == null) throw new Error('url not found');
    fetch(url, { credentials: 'same-origin' })
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html;

        window.$('.sidebar-toggle').on('click', e => {
          window.$('.app').toggleClass('is-expanded');
          e.preventDefault();
        });

        this.data.set('loaded', 'true');

        document.dispatchEvent(new Event("liveSocket:reconnect"));
      })
      .catch(() => {
        // console.error('Kunde inte ladda modaler. Var god ladda om sidan.')
      });
  }
}
